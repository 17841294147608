import { useAccount } from '@src/contexts/auth-context';
import { Path } from '@src/navigations/routes';
import React from 'react';
import { Navigate } from 'react-router-dom';

const Home = () => {
  const { isLoggedIn } = useAccount();

  if (isLoggedIn) {
    return <Navigate to={Path.Dashboard} replace />;
  }

  return <Navigate to={Path.Login} replace />;
};

export default Home;
