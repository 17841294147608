import React from 'react';
import { Helmet } from 'react-helmet-async';

interface Props {
  pageTitle: string;
  pageDescription?: string;
}

const Meta = ({ pageTitle, pageDescription = '' }: Props) => {
  return (
    <Helmet>
      <html className="ss-bg-page-background" />
      <title>{pageTitle} - Sprint</title>
      <meta name="description" content={pageDescription} />

      {/*
       * Add extra meta data
       */}
    </Helmet>
  );
};

export default Meta;
