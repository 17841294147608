export const USER_STORAGE_KEY = 'admin_user';

// Query keys
export const DATE_SELECTOR_QUERY = 'date-selector';
export const START_DATE_QUERY = 'start-date';
export const END_DATE_QUERY = 'end-date';
export const EXACT_DATE_QUERY = 'exact-date';
export const EXPIRY_DATE_QUERY = 'expiry-date';
export const VERIFICATION_STATUS_QUERY = 'verification';
export const COUNTRY_QUERY = 'country';
export const SEARCH_QUERY = 'search';
export const PAYMENT_METHOD_QUERY = 'payment-method';
export const STATUS_QUERY = 'status';
export const TYPE_QUERY = 'type';
export const PAGE_NUMBER_QUERY = 'page';
export const TAB_QUERY_KEY = 'tab';
export const SENDER_QUERY_KEY = 'sender';
export const TARGET_QUERY_KEY = 'target';
export const CURRENCY_QUERY_KEY = 'currency';
