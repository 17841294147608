import ClientApi from '@src/types/client';
import { USER_STORAGE_KEY } from '@src/constants';

export type State = {
  user?: ClientApi.General.LoggedInUser['Data'];
};

export const getState = (): State | undefined => {
  try {
    const serializedState = localStorage.getItem(USER_STORAGE_KEY);
    if (serializedState === null) {
      return undefined;
    }

    const parsedUser = JSON.parse(serializedState) as ClientApi.General.LoggedInUser['Data'];
    return { user: parsedUser };
  } catch (err) {
    return undefined;
  }
};

export const setState = (user: ClientApi.General.LoggedInUser['Data']): void => {
  try {
    const serializedState = JSON.stringify(user);

    localStorage.setItem(USER_STORAGE_KEY, serializedState);
    // eslint-disable-next-line no-empty
  } catch (err) {}
};

export const clearState = (): void => {
  try {
    localStorage.removeItem(USER_STORAGE_KEY);
    // eslint-disable-next-line no-empty
  } catch (err) {}
};
