import { useAccount } from '@src/contexts/auth-context';
import { Path } from '@src/navigations/routes';
import React, { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const AdminHeader = lazy(() => import('./components/adminHeader'));
export const SideNavBar = lazy(() => import('./components/sideNavBar'));

const AdminLayout = () => {
  const { isLoggedIn } = useAccount();

  if (!isLoggedIn) {
    return <Navigate to={Path.Login} replace />;
  }

  return (
    <div>
      <SideNavBar />
      <div className="ss-ml-[270px]">
        <Outlet />
      </div>
    </div>
  );
};

export default AdminLayout;
