import SuspenseWrapper from '@src/components/suspenseWrapper';
import Loader from '@src/components/loader';
import lazyWithPreload from '@src/utils/lazyWithPreload';
import { RouteObject } from 'react-router-dom';
import Error from '@src/pages/error';
import Box from '@sendsprint/ui-react/dist/components/Box';
import { Path } from '../routes';

export const LoginPage = lazyWithPreload(() => import('@src/pages/auth/login'));
export const ResetPasswordPage = lazyWithPreload(() => import('@src/pages/auth/resetPassword'));
export const ForgetPasswordPage = lazyWithPreload(() => import('@src/pages/auth/forgetPassword'));
export const CheckMailPage = lazyWithPreload(() => import('@src/pages/auth/checkMail'));

const routesData = [
  { path: Path.Login, component: <LoginPage /> },
  { path: Path.ResetPassword, component: <ResetPasswordPage /> },
  { path: Path.ForgetPassword, component: <ForgetPasswordPage /> },
  { path: Path.CheckMail, component: <CheckMailPage /> }
];

export const authRoutes: RouteObject[] = routesData.map(({ component, path }) => ({
  path,
  element: (
    <SuspenseWrapper
      errorFallback={({ resetError }) => (
        <Box>
          <Error showHeader={false} removeContainer height="content" resetError={resetError} />
        </Box>
      )}
      suspenseFallback={
        <Box className="ss-pt-20">
          <Loader height="content" showTimedOutScreen />
        </Box>
      }>
      {component}
    </SuspenseWrapper>
  )
}));
