/* eslint-disable @typescript-eslint/no-explicit-any */
import { UserAxiosConfig } from '@src/config/AxiosConfig';
import ClientApi from '@src/types/client';

import { AxiosResponse } from 'axios';

export const loginService = async (
  payload: ClientApi.Account.Login.Request
): Promise<ClientApi.General.LoggedInUser> => {
  return UserAxiosConfig.post<
    ClientApi.Account.Login.Response,
    AxiosResponse<ClientApi.Account.Login.Response>,
    ClientApi.Account.Login.Request
  >(`/api/v1/admin/login`, payload).then((data: any) => {
    return data.data;
  });
};

export const ForgetPasswordService = async (
  payload: ClientApi.Account.ForgotPassword.Request
): Promise<ClientApi.General.ForgotPasswordInUser> => {
  return UserAxiosConfig.post<
    ClientApi.Account.ForgotPassword.Response,
    AxiosResponse<ClientApi.Account.ForgotPassword.Response>,
    ClientApi.Account.ForgotPassword.Request
  >(`/api/v1/admin/reset-password-by-email/initiate?AdminEmail=${payload.email}`, payload).then(
    (data: any) => {
      return data;
    }
  );
};

export const ResetPasswordService = async (
  payload: ClientApi.Account.ResetPassword.Request
): Promise<ClientApi.General.ResetPasswordUser> => {
  return UserAxiosConfig.put<
    ClientApi.Account.ResetPassword.Response,
    AxiosResponse<ClientApi.Account.ResetPassword.Response>,
    ClientApi.Account.ResetPassword.Request
  >(`/api/v1/admin/reset-password-by-email/complete`, payload).then((data: any) => {
    return data;
  });
};

export const refreshTokenService = async () => {
  return UserAxiosConfig.put(`/api/v1/admin/refresh-session-token`, {}).then((res) => {
    return res.data.Data.SessionToken;
  });
};

export const logoutService = async () => {
  return UserAxiosConfig.put(`/api/v1/admin/logout`, {}).then((res) => {
    return res.data;
  });
};
