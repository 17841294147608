import Api from '@sendsprint/api-types';
import { UserAxiosConfig } from '@src/config';
import { AddToast, ToastType } from '@src/contexts/toast-context';
import { AxiosError, AxiosResponse } from 'axios';
import { getState } from './stateStorage';
import { getEnvData } from './env';

type ConfigureInterceptorProps = {
  addToast: AddToast;
  logout: () => void;
};

export function configureInterceptors({ addToast, logout }: ConfigureInterceptorProps) {
  const addApiErrorToast = (content: ToastType['content']) => {
    addToast(content, { appearance: 'warning' });
  };

  const responseInterceptor = UserAxiosConfig.interceptors.response.use(
    function (response: AxiosResponse<Api.Endpoint.Response.SuccessResponse>) {
      return response;
    },
    function (
      error: AxiosError<
        Api.Endpoint.Response.ErrorResponse | Api.Endpoint.Response.InternalServerErrorResponse
      >
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errorResponse = error.response?.data as Api.Endpoint.Response.ErrorResponse | any;
      const serverErrorResponse = error.response?.data as
        | Api.Endpoint.Response.InternalServerErrorResponse
        | undefined;
      const errorStatus = error.response?.status;

      console.log({ error });

      const getReturnObject = () => Promise.reject(error.response?.data || error);

      // Invalid or expired hash, then log out
      if (
        // errorResponse?.ResponseCode === Api.Endpoint.Response.Code.InvalidHash ||
        errorResponse?.httpStatusCode === 401
      ) {
        // this is for error handling of register form
        if (errorResponse?.ResponseMessage === 'Email already registered before. Please login') {
          logout();
          addApiErrorToast({
            title: 'Your session has expired',
            body: 'Please log in to your account'
          });
        } else {
          logout();
          addApiErrorToast({
            title: 'Your session has expired',
            body: 'Please log in to your account'
          });
        }
        return getReturnObject();
      }

      if (
        errorStatus === 401 ||
        errorResponse?.ResponseMessage === 'Invalid session token.' ||
        errorResponse?.ResponseMessage === 'Session token has expired.'
      ) {
        logout();
        addApiErrorToast({
          title: 'Your session has expired',
          body: 'Please log in to your account'
        });
      }

      if (error?.config?.showToastOnError) {
        const errorMessage: string =
          errorResponse?.ResponseMessage || serverErrorResponse?.Message || error.message;

        addApiErrorToast({ title: 'Error', body: errorMessage });
      }

      return getReturnObject();
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const requestInterceptor = UserAxiosConfig.interceptors.request.use((request: any) => {
    // Get the token from local storage
    const userState = getState();
    const envData = getEnvData();
    const token = userState?.user?.SessionToken;

    request.headers.ApplicationID = envData.API_APPLICATION_ID;
    request.headers.ApplicationSecret = envData.API_SECRET_KEY;

    // If the token exists, set the Authorization header
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
      request.headers.SessionToken = ` ${token}`;
    }

    return request;
  });

  return function cleanupInterceptors(): void {
    UserAxiosConfig.interceptors.response.eject(responseInterceptor);
    UserAxiosConfig.interceptors.request.eject(requestInterceptor);
  };
}
