import React, { lazy } from 'react';
import { ErrorProps } from './components/errorInner';
import SuspenseWrapper from '@src/components/suspenseWrapper';

const ErrorInner = lazy(() => import('./components/errorInner'));

const Error = ({ resetError, showMeta = true, ...props }: ErrorProps) => {
  return (
    <SuspenseWrapper>
      <ErrorInner resetError={resetError} showMeta={showMeta} {...props} />
    </SuspenseWrapper>
  );
};

export default Error;
