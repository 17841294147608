export type EnvData = {
  API_BASE_URL: string;
  API_STAGING_BASE_URL: string;
  API_APPLICATION_ID: string;
  API_SECRET_KEY: string;
  APP_VERSION: string;
  SENTRY_DSN_URL: string;
  ENVIRONMENT: string;
  IS_DEV: boolean;
  IS_STAGING: boolean;
  IS_PROD: boolean;
};

export function getEnvData(): Readonly<EnvData> {
  const ENVIRONMENT = `${process.env.REACT_APP_ENV}`;
  const IS_DEV = ENVIRONMENT === 'development';

  return {
    API_BASE_URL: `${process.env.REACT_APP_API_BASE_URL}`,
    API_STAGING_BASE_URL: `${process.env.REACT_APP_API_STAGING_BASE_URL}`,
    API_SECRET_KEY: `${process.env.REACT_APP_API_SECRET_KEY}`,
    API_APPLICATION_ID: `${process.env.REACT_APP_API_APPLICATION_ID}`,
    APP_VERSION: `${process.env.REACT_APP_VERSION}`,
    SENTRY_DSN_URL: `${process.env.REACT_APP_SENTRY_DSN_URL}`,
    ENVIRONMENT,
    IS_DEV,
    IS_STAGING: ENVIRONMENT === 'staging',
    IS_PROD: ENVIRONMENT === 'production'
  };
}

// under context auth-context.ts  for refresh token api usage in b2b
