import { init, reactRouterV6BrowserTracingIntegration, replayIntegration } from '@sentry/react';
import { getEnvData } from '@src/utils/env';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';

const { APP_VERSION, ENVIRONMENT, SENTRY_DSN_URL, IS_PROD } = getEnvData();
const sendSentryReport = IS_PROD && !window.location.href.includes('localhost');

export default function setupSentry() {
  init({
    environment: ENVIRONMENT,
    release: `sprint-frontend-admin@${APP_VERSION}`,
    dsn: sendSentryReport ? SENTRY_DSN_URL : undefined,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      replayIntegration()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}
