import SuspenseWrapper from '@src/components/suspenseWrapper';
import Loader from '@src/components/loader';
import lazyWithPreload from '@src/utils/lazyWithPreload';
import { RouteObject } from 'react-router-dom';
import Error from '@src/pages/error';
import Box from '@sendsprint/ui-react/dist/components/Box';
import { Path } from '../routes';

export const Dashboard = lazyWithPreload(() => import('@src/pages/admin/dashboard'));
export const Senders = lazyWithPreload(() => import('@src/pages/admin/senders/senders'));
export const SendersDetailIdPage = lazyWithPreload(
  () => import('@src/pages/admin/senders/sendersDetails')
);

export const Recipients = lazyWithPreload(() => import('@src/pages/admin/recipients/recipients'));
export const RecipientsDetailIdPage = lazyWithPreload(
  () => import('@src/pages/admin/recipients/recipientsDetails')
);

export const Transactions = lazyWithPreload(
  () => import('@src/pages/admin/transactions/transactions')
);
export const TransferTransactionsDetailIdPage = lazyWithPreload(
  () => import('@src/pages/admin/transactions/transferTransactionDetails')
);
export const GiftTransactionsDetailIdPage = lazyWithPreload(
  () => import('@src/pages/admin/transactions/giftTransactionDetails')
);

export const CodesPage = lazyWithPreload(() => import('@src/pages/admin/codes/codes'));
export const PaymentCodesDetailsIdPage = lazyWithPreload(
  () => import('@src/pages/admin/codes/paymentCodeDetails')
);
export const DiscountCodesDetailsIdPage = lazyWithPreload(
  () => import('@src/pages/admin/codes/discountCodesDetails')
);

export const RateAndFeePage = lazyWithPreload(
  () => import('@src/pages/admin/ratesAndCodes/rateAndFees')
);
export const RateAndFeesDetailIdPage = lazyWithPreload(
  () => import('@src/pages/admin/ratesAndCodes/rateAndFeeDetails')
);
export const BusinessModulePage = lazyWithPreload(
  () => import('@src/pages/admin/bussinessModule/business')
);
export const BusinessDetailIdPage = lazyWithPreload(
  () => import('@src/pages/admin/bussinessModule/bussinessetailsId')
);

export const BusinessUserPage = lazyWithPreload(
  () => import('@src/pages/admin/businessUser/businessUser')
);
export const BusinessUserDetailIdPage = lazyWithPreload(
  () => import('@src/pages/admin/businessUser/businessUserDetails')
);

export const BusinessTransactionPage = lazyWithPreload(
  () => import('@src/pages/admin/businessTransactions')
);
export const BusinessRecieverTransactionIdPage = lazyWithPreload(
  () => import('@src/pages/admin/businessTransactions/businessReceiverTransactionId')
);
export const BusinessSenderTransactionIdPage = lazyWithPreload(
  () => import('@src/pages/admin/businessTransactions/businessSenderTransactionId')
);

export const BusinessRecipientsPage = lazyWithPreload(
  () => import('@src/pages/admin/businessRecipients/businessRecipients')
);
export const BusinessRecipientsIdPage = lazyWithPreload(
  () => import('@src/pages/admin/businessRecipients/businessRecipientDetails')
);

export const BusinessCustomersPage = lazyWithPreload(
  () => import('@src/pages/admin/businessCustomers/businessCustomers')
);
export const BusinessCustomersIdPage = lazyWithPreload(
  () => import('@src/pages/admin/businessCustomers/businessCustomerDetails')
);

export const AdminUsersPage = lazyWithPreload(() => import('@src/pages/admin/adminUsers'));
export const AminUsersDetailsIdPage = lazyWithPreload(
  () => import('@src/pages/admin/adminUsers/adminUsersDetailsId')
);

export const PermissionPage = lazyWithPreload(() => import('@src/pages/admin/permission'));
export const SuperAdminPage = lazyWithPreload(
  () => import('@src/pages/admin/permission/superadmin')
);
export const TeamLeadPage = lazyWithPreload(() => import('@src/pages/admin/permission/teamlead'));
export const TeamMemberPage = lazyWithPreload(
  () => import('@src/pages/admin/permission/teammember')
);

const routesData = [
  // Dashboard
  { path: Path.Dashboard, component: <Dashboard /> },
  // Senders
  { path: Path.Senders, component: <Senders /> },
  { path: `${Path.Senders}/:id`, component: <SendersDetailIdPage /> },
  // Recipients
  { path: Path.Recipients, component: <Recipients /> },
  { path: `${Path.Recipients}/:id`, component: <RecipientsDetailIdPage /> },
  // Transactions
  { path: Path.Transactions, component: <Transactions /> },
  {
    path: `${Path.Transactions}/transfer/:txref`,
    component: <TransferTransactionsDetailIdPage />
  },
  {
    path: `${Path.Transactions}/gift/:txref`,
    component: <GiftTransactionsDetailIdPage />
  },
  // Codes
  { path: Path.Codes, component: <CodesPage /> },
  {
    path: `${Path.CodesPaymentCode}/:code`,
    component: <PaymentCodesDetailsIdPage />
  },
  { path: `${Path.CodesDiscountCode}/:code`, component: <DiscountCodesDetailsIdPage /> },
  // Rates and fees
  { path: Path.RateAndFee, component: <RateAndFeePage /> },
  { path: `${Path.RateAndFee}/:id`, component: <RateAndFeesDetailIdPage /> },
  { path: Path.BusinessModule, component: <BusinessModulePage /> },
  { path: `${Path.BusinessModule}/bussiness-Detail-id/:id`, component: <BusinessDetailIdPage /> },
  // Business users
  { path: Path.BusinessUser, component: <BusinessUserPage /> },
  {
    path: `${Path.BusinessUser}/:email`,
    component: <BusinessUserDetailIdPage />
  },
  { path: Path.BusinessTransaction, component: <BusinessTransactionPage /> },
  {
    path: `${Path.BusinessTransaction}/business-Sender-Transaction-id/:id/:bankName`,
    component: <BusinessSenderTransactionIdPage />
  },
  {
    path: `${Path.BusinessTransaction}/business-Receiver-Transaction-id/:id`,
    component: <BusinessRecieverTransactionIdPage />
  },
  // Business recipients
  { path: Path.BusinessRecipients, component: <BusinessRecipientsPage /> },
  {
    path: `${Path.BusinessRecipients}/:id`,
    component: <BusinessRecipientsIdPage />
  },
  // Business customers
  { path: Path.BusinessCustomers, component: <BusinessCustomersPage /> },
  {
    path: `${Path.BusinessCustomers}/:email`,
    component: <BusinessCustomersIdPage />
  },
  { path: Path.AdminUsers, component: <AdminUsersPage /> },
  { path: `${Path.AdminUsers}/adminUsers-Detail-id/:id`, component: <AminUsersDetailsIdPage /> },
  { path: Path.Permission, component: <PermissionPage /> },
  { path: `${Path.Permission}/permissions-id/:id`, component: <SuperAdminPage /> },
  { path: Path.TeamLead, component: <TeamLeadPage /> },
  { path: Path.TeamMember, component: <TeamMemberPage /> }
];

export const adminUserRoutes: RouteObject[] = routesData.map(({ component, path }) => ({
  path,
  element: (
    <SuspenseWrapper
      errorFallback={({ resetError }) => (
        <Box>
          <Error showHeader={false} resetError={resetError} />
        </Box>
      )}
      suspenseFallback={<Loader showTimedOutScreen />}>
      {component}
    </SuspenseWrapper>
  )
}));
