import Box, { BoxProps } from '@sendsprint/ui-react/dist/components/Box';
import React, { PropsWithChildren } from 'react';
import { FC } from 'react';
import cs from 'classnames';

type ContainerProps = PropsWithChildren<{
  className?: string;
  fluid?: boolean;
}> &
  BoxProps;
const Container: FC<ContainerProps> = ({ children, className, fluid, ...props }) => {
  return (
    <Box
      {...props}
      className={cs('ss-mx-auto', {
        'ss-px-10 ss-w-full': fluid,
        'ss-w-[90%] ss-max-w-[1700px]': !fluid,
        [`${className}`]: className
      })}>
      {children}
    </Box>
  );
};

export default Container;
