// eslint-disable-next-line no-shadow
export enum Path {
  Home = '/',
  /**
   * Auth Pages
   */
  Login = '/login',
  ResetPassword = '/resetPassword',
  // ResetPassword = `/resetPassword?EmailAddress=`,

  ForgetPassword = '/forgetPassword',
  CheckMail = '/checkMail',

  AdminVerifyEmail = '/verify-email',

  /**
   * Admin Pages
   * The rest can have the format of '/admin/[pageName]
   */
  Dashboard = '/admin/dashboard',
  Senders = '/admin/senders',
  Recipients = '/admin/recipients',
  Transactions = '/admin/transactions',

  BusinessModule = '/admin/bussinessModule',
  BusinessModuleDetails = '/admin/bussinessModule/bussiness-Detail-id',
  BusinessUser = '/admin/bussinessUser',
  BusinessDetailsId = '/admin/bussiness-Detail-id/:id/:type',
  BusinessTransaction = '/admin/businessTransaction',
  BusinessRecipients = '/admin/businessRecipients',
  BusinessCustomers = '/admin/businessCustomers',
  Permission = '/admin/permissions',

  AdminUsers = '/admin/adminUsers',
  SuperAdmin = '/admin/superAdmins',
  TeamLead = '/admin/teamLeads',
  TeamMember = '/admin/teamMembers',
  Codes = '/admin/codes',
  CodesPaymentCode = '/admin/codes/payment-code',
  CodesDiscountCode = '/admin/codes/discount-code',
  RateAndFee = '/admin/rateAndFee'
}
