import { useAccount } from '@src/contexts/auth-context';
import { useToasts } from '@src/contexts/toast-context';
import { configureInterceptors } from '@src/utils/configureInterceptors';
import React, { ReactNode, useEffect } from 'react';

interface Props {
  children?: ReactNode;
}

const AppLayoutInner = ({ children }: Props) => {
  const { addToast } = useToasts();
  const { handleLogoutSuccess } = useAccount();

  useEffect(() => {
    return configureInterceptors({ addToast, logout: handleLogoutSuccess });
  }, [addToast]);

  return <>{children}</>;
};

export default AppLayoutInner;
