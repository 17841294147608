import ErrorBoundary from '@src/components/errorBoundary';
import AppLayout from '@src/layouts/appLayout';
import AuthLayout from '@src/layouts/auth';
import Home from '@src/pages/home';
import NotFound from '@src/pages/notFound';
import { createBrowserRouter } from 'react-router-dom';
import { authRoutes } from './subRoutes/authRoutes';
import { adminUserRoutes } from './subRoutes/adminUserRoutes';
import AdminLayout from '@src/layouts/admin';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Home />
      },
      {
        element: <AuthLayout />,
        children: authRoutes
      },
      {
        element: <AdminLayout />,
        children: adminUserRoutes
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
]);
